import React from "react"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Button from "../../components/button"
import Constrain from "../../components/constrain"
import Heading from "../../components/heading"
import Input from "../../components/input"
import Layout from "../../layouts/default"
import PageTitle from "../../components/page-title"
import Paragraph from "../../components/paragraph"
import Seo from "../../components/seo"
import Small from "../../components/small"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"

const NewsletterIndex = () => (
  <Layout backdrop="newsletter">
    <Seo
      title="Newsletter Anmeldung"
      description="Aktuelle Ausstellungen, digitale Angebote und Veranstaltungen. Mit unserem E-Mail-Newsletter informieren wir Sie regelmäßig über das aktuelle Programm des Hölderlinturms."
    />
    <Stack space={[12, 24]}>
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Newsletter",
              link: "/newsletter",
            },
          ]}
        />
        <PageTitle>Neues aus dem Hölderlinturm</PageTitle>
        <Paragraph>
          Aktuelle Ausstellungen, digitale Angebote und Veranstaltungen. Mit
          unserem E-Mail-Newsletter informieren wir Sie regelmäßig über das
          aktuelle Programm des Hölderlinturms. Melden Sie sich jetzt an:
        </Paragraph>

        <Constrain>
          <Box bg="muted" p={[6, 12]}>
            <Stack
              as="form"
              method="post"
              action="https://t9c4b9b43.emailsys2a.net/28/3639/3afdcef505/subscribe/form.html?_g=1626242294"
            >
              <Heading as="h2" level={3}>
                Newsletter-Anmeldung
              </Heading>
              <Stack space={6}>
                <Input
                  label="Ihre E-Mail Adresse"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-Mail Adresse eingeben"
                />
                <Button as="button" type="submit">
                  Jetzt abonnieren
                </Button>
              </Stack>
              <Small>
                Ihre Daten werden vertraulich und in Übereinstimmung mit den
                Bestimmungen des Deutschen Datenschutzrechts behandelt. Ihr
                Abonnement können Sie jederzeit beenden. Bitte beachten Sie dazu
                auch unsere{" "}
                <TextLink href="/datenschutz" target="_blank">
                  Datenschutzerklärung
                </TextLink>
                .
              </Small>
            </Stack>
          </Box>
        </Constrain>
      </Stack>
    </Stack>
  </Layout>
)

export default NewsletterIndex
